<template>
  <div class="error_container">
    <div class="error_top">
      <TopCard ref="TopCardRef"
               text="思维导图">
      </TopCard>

    </div>

    <div class="error_bottom">
      <dropDown :list='subjectList'
                :index='subjectIndex'
                style="width: 13%;height: 45rem;margin-left: 24rem;"
                attribute_name='subject_name'
                @changeMenu='subjectChange'
                background='#2196f3' />
      <!-- <div class="error_left"
           id="errorLeft"
           ref="errorLeftRef">
        <leftTopic @showPost="showPost"
                   :topic="questionInfo"
                   @biji="biji" />
      </div> -->
      <button class="line_move"
              id="moveLine"
              title="鼠标按住拖动"
              @mousedown="moveAble"></button>

      <div class="error_right">
        <Map ref="mapRef"
             @setFloatData="setFloatData" />
        <!-- </div> -->

      </div>
      <div class="float_window"
           ref="floatWindowRef">
        <div class="move_box"
             @mousedown="windowCanMove"></div>
        <FloatWindow v-if="showWindow"
                     @close="closeWindow"
                     ref="windowRef" />
      </div>

    </div>
    <!-- <PostForm ref="FormRef"
              title='发帖'
              type='1'
              @submit="submit"
              @cancel='cancel'
              :isDisable='true' />
    <Biji ref="bijiRef"
          @updateInfo="getTopicInfo" /> -->
  </div>

</template>

<script setup>
import TopCard from '@/views/components/top_card/index.vue'

import Map from './components/map.vue'
import FloatWindow from './components/floatWindow.vue'
import { getUserSubject } from '@/api/subject.js'
import { addPost } from '@/api/post.js'
import { nextTick, onMounted, ref, reactive, onUnmounted } from 'vue'
import { getMapMind, getInfo, getKnowledgeInfo } from '@/api/errorBook.js'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import dropDown from '@/views/components/dropdown/index.vue'


let route = useRoute()
let showWindow = ref(false)
let mapRef = ref()
let subject_id = ref('')
let userinfo = ref(null)
let userId = ref('')
let subjectIndex = ref(0)
let subjectList = ref([])
onMounted(() => {
  getSubject()
  userinfo.value = JSON.parse(window.localStorage.getItem('userInfo'))
  userId.value = Number(userinfo.value.user_id)
  nextTick(() => {
    // mapRef.value.init()
    document.addEventListener('mousemove', moveDiv)
    document.addEventListener('mousemove', moveWindow)
    document.addEventListener('mouseup', moveDisAble)
    document.addEventListener('mouseup', winDisMove)
  })

 

})
const getSubject = async () => {
  const { data } = await getUserSubject()
  subjectList.value = data.list
  subject_id.value = window.localStorage.getItem('physical_subjectId')
  subjectList.value.forEach((item, index) => {
 
    if (item.subject_id == subject_id.value) {
      subjectIndex.value = index;
    }
  });
  getTopicInfo()
}
// 科目切换
const subjectChange = (index) => {
  subjectIndex.value = index
  subjectList.value[subjectIndex.value].subject_id
  window.localStorage.setItem('physical_subjectId', subjectList.value[subjectIndex.value].subject_id)
  getTopicInfo()
}


// 鼠标拖到调整作用大小宽度
let isMove = ref(false)
const moveAble = (bool) => {
  isMove.value = true
  document.onselectstart = function () {
    return false
  }
}
const moveDisAble = (bool) => {
  isMove.value = false
  document.onselectstart = function () {
    return true
  }
}
let errorLeftRef = ref()
const moveDiv = (e) => {
  if (isMove.value) {
    let divWidth = errorLeftRef.value.clientWidth
    let screenWidth = document.body.clientWidth
    if ((screenWidth - divWidth <= 375 && screenWidth - e.clientX <= 375) || (divWidth <= 514 && e.clientX <= 514)) {
      return
    }
    errorLeftRef.value.style.width = e.clientX + 'px'
  }
}

let questionInfo = reactive([])

const getTopicInfo = async () => {
 
  const { data } = await getMapMind({ subject_id: window.localStorage.getItem('physical_subjectId') || subjectList.value[subjectIndex.value].subject_id, uid: userId.value, platform: 1 })

  questionInfo = data



  nextTick(() => {
    getMapList()
  })

}
const getMapList = () => {
  let list = {
    // id: 'topic_root',
    // topic: '题目',
    // children: []
  }

  if (questionInfo) {
    // list.children.push({
    list = {
      id: 'knowledge_root',
      topic: '考点',
      children: flatKnowledgeList(questionInfo)
    }
  }

  mapRef.value.init(list)
}


//处理知识点数据
const flatKnowledgeList = (arr) => {
  let list = []
  arr.forEach((item, index) => {
    let obj = {
      id: item.knowledge_id,
      type: 'knowledge',
      topic: item.knowledge_desc + '<span style="font-family: iconfont;font-size:24rem;vertical-align: bottom;line-height: 18px;margin-right: -10px ">&#xe604;</span>',
      // topic: item.knowledge_desc,
      direction: index % 2 == 0 ? 'left' : 'right',
      parentId: item.knowledge_parent_id,
      score: item.score,
      children: []
    }
    if (item.child && item.child.length) {
      obj.children = flatKnowledgeList(item.child)
    }
    list.push(obj)
  });
  return list;
}



let sessionNodeList = ref([])
let floatData = ref([])
let windowRef = ref()
let floatWindowRef = ref()

let winMove = ref(false)
let oX = ref(0)
let oY = ref(0)
const windowCanMove = (e) => {
  oX.value = e.pageX - floatWindowRef.value.offsetLeft
  oY.value = e.pageY - floatWindowRef.value.offsetTop
  winMove.value = true
  document.onselectstart = function () {
    return false
  }
}
const winDisMove = () => {
  winMove.value = false
  document.onselectstart = function () {
    return true
  }
}
const moveWindow = (e) => {
  if (winMove.value) {
    let offsetBottom = document.body.clientHeight - (floatWindowRef.value.offsetTop + floatWindowRef.value.offsetHeight + oY.value)
    let offsetRight = document.body.clientWidth - (floatWindowRef.value.offsetLeft + floatWindowRef.value.clientWidth)
    // 上下边界限制
    if ((floatWindowRef.value.offsetTop > 0 || e.pageY > oY.value) && (offsetBottom > 0 || e.pageY < document.body.clientHeight - floatWindowRef.value.offsetHeight)) {
      floatWindowRef.value.style.top = e.pageY - oY.value + 'px'
    }
    if ((floatWindowRef.value.offsetLeft > 0 || e.pageX > oX.value) && (offsetRight > 0 || e.pageX < document.body.clientWidth - floatWindowRef.value.offsetWidth)) {
      floatWindowRef.value.style.left = e.pageX - oX.value + 'px'
    }
  }
}

let nodeId = ref()
// 设置浮窗数据
const setFloatData = async (id, parentId, type) => {
  if (id == 'knowledge_root' || id == 'topic_root') return
  if (nodeId.value == id && showWindow.value) return
  nodeId.value = id
  showWindow.value = true
  let node = isSessionNode(type, id) //查找节点有无缓存
  if (node.id) {
    floatData.value = node.data
    nextTick(() => {
      windowRef.value.windowData = node.data
    })
    return
  }
  let form
  if (type == 'knowledge') {
    const { data } = await getKnowledgeInfo({
      knowledge_id: id
    })
    form = {
      type: 'knowledge',
      knowledge_id: data.knowledge_id,
      subjectId: data.subject_id,
      title: data.knowledge_desc,//标题
      star: data.knowledge_point_star ? data.knowledge_point_star : 0,//知识点星级
      time: data.paper_time,//做卷时间
      score: data.score,//得分率
      count: data.count,
      video_list: data.video_list//关联的考点视频
    }
    // console.log(data)
    floatData.value = form
    nextTick(() => {
      windowRef.value.windowData = form
    })
    setSessionNode('knowledge', id, form)
  }
}

const isSessionNode = (type, id) => {
  sessionNodeList.value = JSON.parse(window.sessionStorage.getItem("sessionNodeList")) || []
  if (!sessionNodeList.value.length) return false
  let node = sessionNodeList.value.find(item => item.type == type && item.id == id
  )

  if (!node) { return false }
  return node
}
const setSessionNode = (type, id, data) => {
  sessionNodeList.value.push({
    type: type,
    id,
    data
  })
  window.sessionStorage.setItem("sessionNodeList", JSON.stringify(sessionNodeList.value))
}

onUnmounted(() => {
  window.sessionStorage.removeItem("sessionNodeList")
})

// 思维导图弹窗
const closeWindow = () => {
  showWindow.value = false
  mapRef.value.cancelSelected()
}
let FormRef = ref()
let bijiRef = ref()
const showPost = () => {
  let form = {
    discuss_title: '',
    discuss_content: '',
    board_id: Number(subject_id.value),
    // question_id: rands(Number(topic.value.question_id)),
    question_id: Number(questionInfo.question_id),

    discuss_image: []
  }
  nextTick(() => {
    FormRef.value.form = form
    FormRef.value.dialogVisible = true
  })
}

// 点击笔记按钮
const biji = () => {
  // bijiRef.value.setData(topic.value)
  bijiRef.value.setData(questionInfo)

}

//发帖弹窗》提交
const submit = async () => {
  const { status } = await addPost(
    FormRef.value.form1
  )
  if (status == 200) {
    message.success('发帖成功')
    cancel()
  }

}
const cancel = () => {
  FormRef.value.dialogVisible = false
}


</script>

<style lang="scss" scoped>
.error_container {
  background: #f5f5f5;
  .error_bottom {
    height: calc(100vh - 100rem);
    display: flex;
    position: relative;
    flex-direction: column;
    :deep .dropdown_wrap {
      padding: 5rem;
    }
  }
}
.line_move {
  width: 3px;
  padding: 0;
  border: 0;
  background: #e6e6e6;
  cursor: col-resize;
}
.error_left {
  background: #fff;
  width: 50%;
  height: 100%;
  overflow: auto;
}
.error_right {
  flex: 1;
  margin-left: 24px;
}
.float_window {
  position: absolute;
  left: 52%;
  top: 24px;
  z-index: 5;
  .move_box {
    position: absolute;
    // background: #333;
    left: 0;
    width: 100%;
    border-radius: 20rem 20rem 0 0;
    height: 20rem;
    cursor: move;
  }
}
// .map_wrap {
//   height: calc(100vh - 100rem);
// }
</style>